<template>
  <div id="App">
    <VThemeProvider>
      <RouteNamesProvider>
        <RouterView />
      </RouteNamesProvider>
    </VThemeProvider>
  </div>
</template>

<script>
import * as profileApi from '@/services/api/profile';
import { usedesk, usedeskNew } from '@/plugins/usedesk/index';
import RouteNamesProvider from '@/components/providers/RouteNamesProvider';
import { FEATURE_FLAGS } from '@/helpers/constants';

export default {
  name: 'App',

  components: {
    RouteNamesProvider,
  },

  async created() {
    try {
      const profile = await profileApi.profileGet();
      if (this.$store.getters.hasFlag(FEATURE_FLAGS.USE_HELPDESKEDDY)) {
        usedeskNew(profile);
      } else {
        usedesk(profile);
      }
    } catch (err) {
      //  no-err
    }
  },
};
</script>

<style src="@fortawesome/fontawesome-pro/css/all.css"></style>
<style lang="scss" src="@/styles/main.scss"></style>
